import DataService from "./dataService";

class ApiUpload {
    uploadImg(file) {
        var formData = new FormData();
        formData.append('file', file);
        return DataService.post("/upload_img", formData);
    }
}

export default new ApiUpload();