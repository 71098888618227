<template>
    <div class="upload-image">
        <button class="btn btn-outline-info btn-sm btn-choose" disabled v-if="loading">
            <span>Đang tải...</span>
        </button>
        <button class="btn btn-outline-info btn-sm btn-choose" @click="chooseImage" v-else style="width: 84px;">
            <i class="fas fa-upload"></i><span class="ml-3">Chọn</span>
        </button>
        <b-form-file accept="image/*" multiple v-bind:id="'choose-image-' + _uid" @change="changeFile" v-if="multiple">
        </b-form-file>
        <b-form-file accept="image/*" v-bind:id="'choose-image-' + _uid" @change="changeFile" v-else></b-form-file>
        <div class="preview">
            <div class="preview-item" v-for="(item, index) in images" :key="index">
                <b-img v-bind:src="fullPathFile(item.path)" width="150"></b-img>
                <div class="preview-remove">
                    <button class="btn btn-outline-info btn-sm" @click="removeImage(index)">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ApiUpload from "../../services/apiUpload";

export default {
    props: {
        multiple: null,
        minWidth: null,
        minHeight: null,
        ratio: null,
        callBack: {
            type: Function
        }
    },
    data() {
        return {
            loading: false,
            images: []
        };
    },
    methods: {
        chooseImage() {
            document.getElementById("choose-image-" + this._uid).click();
        },
        removeImage(index) {
            this.images.splice(index, 1);
            this.callBack(this.images);
        },
        dataURItoBlob(dataURI) {
            const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
                ? atob(dataURI.split(',')[1])
                : unescape(dataURI.split(',')[1]);
            const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
            const max = bytes.length;
            const ia = new Uint8Array(max);
            for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
            return new Blob([ia], { type: mime });
        },
        dataURLtoFile(dataurl, filename) {
            const arr = dataurl.split(',')
            const mime = arr[0].match(/:(.*?);/)[1]
            const bstr = atob(arr[1])
            let n = bstr.length
            const u8arr = new Uint8Array(n)
            while (n) {
                u8arr[n - 1] = bstr.charCodeAt(n - 1)
                n -= 1 // to make eslint happy
            }
            return new File([u8arr], filename, { type: mime })
        },
        readURL(file) {
            return new Promise((ok, no) => {
                const reader = new FileReader();
                reader.onload = (e) => this.originalImg = e.target.result;
                reader.readAsDataURL(file); // convert to base64 string
                this.resizeImage({ file: file, maxSize: 512 }).then((resizedImage) => {
                    ok(resizedImage);
                }).catch((err) => {
                    console.error(err);
                    no();
                });
            });
        },
        resizeImage({ file, maxSize }) {
            const reader = new FileReader();
            const image = new Image();
            const canvas = document.createElement('canvas');
            const resize = () => {
                let { width, height } = image;
                if (width > height) {
                    if (width > maxSize) {
                        height *= maxSize / width;
                        width = maxSize;
                    }
                } else if (height > maxSize) {
                    width *= maxSize / height;
                    height = maxSize;
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                const dataUrl = canvas.toDataURL('image/png');
                return this.dataURItoBlob(dataUrl);
            };
            return new Promise((ok) => {
                reader.onload = (readerEvent) => {
                    image.onload = () => ok(resize());
                    image.src = readerEvent.target.result;
                };
                reader.readAsDataURL(file);
            });
        },
        getSizeImage(file) {
            return new Promise((resolve) => {
                var _URL = window.URL || window.webkitURL;
                var img = new Image();
                var objectUrl = _URL.createObjectURL(file);
                img.src = objectUrl;
                img.onload = function () {
                    _URL.revokeObjectURL(objectUrl);
                    return resolve({
                        width: this.width,
                        height: this.height
                    });
                };
            });
        },
        async checkSizeImage(file) {
            if (this.minWidth || this.minHeight) {
                const size = await this.getSizeImage(file);
                if (this.minWidth && this.minHeight) {
                    if (this.ratio) return (size.width / size.height).toFixed(1) == (this.minWidth / this.minHeight).toFixed(1);
                    return this.minWidth <= size.width && this.minHeight <= size.height;
                }
                if (this.minWidth) return this.minWidth <= size.width;
                if (this.minHeight) return this.minHeight <= size.height;
            }
            return true;
        },
        async changeFile(event) {
            if (event && event.target && event.target.files && event.target.files.length > 0) {
                if (!this.multiple) this.images = [];
                this.loading = true;
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];
                    const checkResult = await this.checkSizeImage(file);
                    if (checkResult) {
                        const blobResize = await this.readURL(file);
                        var result = await ApiUpload.uploadImg(blobResize);
                        if (result && result.saved_path) this.images.push({
                            path: result.saved_path
                        });
                        else this.notifyToast(`Tải lên ${file.name} chưa được`, 'danger');
                    } else this.notifyToast(`Kích thước hình ${file.name} không đúng`, 'danger');
                }
                this.loading = false;
                this.callBack(this.images);
            }
        },
        setImage(_images) {
            this.images = [];
            if (_images && _images.length > 0) {
                for (let i = 0; i < _images.length; i++) {
                    const image = _images[i];
                    if (image) this.images.push({
                        path: image
                    });
                }
            }
        }
    }
};
</script>
